import { Image, ImageProps, mediaBrick, Skeleton } from '@loveholidays/design-system';
import React, { forwardRef, ReactNode, Ref } from 'react';
import { SxStyleProp } from 'theme-ui';

import { Image as ImageType } from '@AuroraTypes';
import { ClassNameProps } from '@ComponentProps';
import { ImageGallery } from '@UX/Image/ImageGallery';

type CardVariant = 'Responsive' | 'ResponsiveTallToWide' | 'Grid' | 'SmallWide' | 'SmallTall';

export interface CardProps extends React.PropsWithChildren<ClassNameProps> {
  /**
   * Whether the card content has a transparent background
   */
  floating?: boolean;
  name?: string;
  images: ImageType[];
  variant: CardVariant;
  topSashes?: ReactNode;
  topRightComponent?: ReactNode; // :shrug:
  bottomSash?: ReactNode;
  imageComponent?: ReactNode;
  height: number | [number, number, number];
  ref?: Ref<any>;
  loading?: boolean;
  loadingContent?: ReactNode;
  fallbackImageSrc?: string;
  lazyImage?: boolean;
  trackingObject?: {
    name: string;
    masterId: string | number;
  };
}

const containerStyles: Record<CardVariant, SxStyleProp> = {
  Responsive: {
    flexDirection: ['column', 'row'],
  },
  ResponsiveTallToWide: {
    flexDirection: ['row', 'column'],
  },
  Grid: {
    flexDirection: 'column',
  },
  SmallWide: {
    flexDirection: 'row',
  },
  SmallTall: {
    flexDirection: 'column',
  },
};

const imageStyles = (floating?: boolean): Record<CardVariant, SxStyleProp> => ({
  Responsive: {
    flex: [null, '0 0 50%', '0 0 38%'],
    borderRadius: floating ? '12' : 0,
  },
  ResponsiveTallToWide: {
    flex: ['0 0 47%', null],
    borderRadius: floating ? '12' : 0,
  },
  Grid: {
    borderRadius: floating ? '12' : 0,
  },
  SmallWide: {
    flex: '0 0 47%',
    borderRadius: floating ? '12' : 0,
  },
  SmallTall: {
    borderRadius: floating ? '12' : 0,
  },
});

const contentStyles = (floating?: boolean): Record<CardVariant, SxStyleProp> => ({
  Responsive: {
    padding: ['s', 'l', 'xl'],
    ...{ paddingLeft: floating ? 0 : undefined },

    ...mediaBrick({
      padding: '2xs',
      ...{ paddingLeft: floating ? 0 : undefined },
    }),
  },
  ResponsiveTallToWide: {
    padding: 'xs',
    paddingLeft: ['xs', floating ? 0 : 'xs'],
  },
  Grid: {
    padding: 's',
    height: '100%',
    ...{ paddingLeft: floating ? 0 : undefined },

    ...mediaBrick({
      padding: '2xs',
      ...{ paddingLeft: floating ? 0 : undefined },
    }),
  },
  SmallWide: {
    padding: 'xs',
  },
  SmallTall: {
    padding: 'xs',
    ...{ paddingLeft: floating ? 0 : undefined },
  },
});

const getImageDimensions = (
  height: number | [number, number, number],
  variant: CardVariant,
): Pick<ImageProps, 'width' | 'height'> => {
  switch (variant) {
    case 'Grid':
      return { width: 225, height };
    case 'SmallWide':
      return { width: 144, height };
    case 'SmallTall':
      return { width: 140, height };
    case 'ResponsiveTallToWide':
      return {
        width: [140, 140, 144],
        height,
      };
    case 'Responsive':
    default: {
      const [mobileHeight, tabletHeight, desktopHeight] =
        typeof height === 'number' ? [height, height, height] : height;

      return {
        width: [
          Math.ceil(mobileHeight * 1.8),
          Math.ceil(tabletHeight * (4 / 3)),
          Math.ceil(desktopHeight * (4 / 3)),
        ],
        height,
      };
    }
  }
};

const defaultLoadingContent = (
  <div>
    <Skeleton sx={{ height: '2em', width: '60%', marginBottom: '1em' }} />
    <Skeleton sx={{ height: '1em', width: '50%', marginBottom: '3em' }} />

    <Skeleton sx={{ height: '2em', width: '50%', marginBottom: '1em' }} />
    <Skeleton sx={{ height: '2em', width: '100%' }} />
  </div>
);

export const Card = forwardRef<HTMLDivElement, CardProps>(
  (
    {
      'data-id': dataId,
      variant,
      name,
      images,
      children,
      floating = false,
      topSashes,
      topRightComponent,
      bottomSash,
      imageComponent,
      height,
      className,
      loading,
      loadingContent,
      fallbackImageSrc,
      lazyImage = true,
      trackingObject,
    },
    ref,
  ) => {
    const shouldRenderTopSashes = topSashes && !loading;
    const shouldRenderBottomSash = bottomSash && !loading;

    return (
      <div
        data-id={dataId}
        ref={ref}
        className={className}
        sx={{
          borderRadius: floating ? '12' : 0,
          display: 'flex',
          ...(!floating && {
            backgroundColor: 'white',
            borderRadius: 12,
            borderColor: 'strokeLightneutral',
            borderWidth: 'outlinedStrokeWeight',
            borderStyle: 'solid',
            overflow: 'hidden',
          }),
          ...containerStyles[variant],
        }}
      >
        <div
          sx={{
            position: 'relative',
            ...imageStyles(floating)[variant],
          }}
        >
          {loading && (
            <Skeleton
              sx={{
                ...imageStyles(floating)[variant],
                height,
              }}
            />
          )}
          {images.length <= 1 &&
            !loading &&
            (imageComponent || (
              <Image
                src={images[0]?.url || fallbackImageSrc}
                alt={images[0]?.description}
                {...getImageDimensions(height, variant)}
                lazy={lazyImage}
                sx={{
                  ...imageStyles(floating)[variant],
                }}
              />
            ))}
          {images.length > 1 && !loading && (
            <ImageGallery
              name={name || ''}
              images={images}
              {...getImageDimensions(height, variant)}
              lazyImage={lazyImage}
              showFullScreenButton={!topRightComponent}
              trackingObject={trackingObject}
            />
          )}
          {!!topRightComponent && (
            <div
              sx={{
                position: 'absolute',
                right: '3xs',
                top: '3xs',
              }}
            >
              {topRightComponent}
            </div>
          )}
          {shouldRenderTopSashes && (
            <div
              sx={{
                position: 'absolute',
                left: '3xs',
                top: '3xs',
                '> *': {
                  marginRight: '3xs',
                },
              }}
            >
              {topSashes}
            </div>
          )}
          {shouldRenderBottomSash && (
            <div
              sx={{
                position: 'absolute',
                right: '3xs',
                bottom: '3xs',
              }}
            >
              {bottomSash}
            </div>
          )}
        </div>
        <div
          sx={{
            ...contentStyles(floating)[variant],
            width: '100%',
          }}
        >
          {loading ? loadingContent || defaultLoadingContent : children}
        </div>
      </div>
    );
  },
);
